import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import SEO from "../components/seo"
import HeroFullWidth from "../components/HeroFullWidth"
import Section from "../components/Section"
import { Container } from "../components/Container"
import SvgBorderTop from "../components/SvgBorderTop"
import AccordionItem from "../components/Accordion/AccordionItem"
import CardBlockWithIcon from "../components/CardBlockWithIcon"
import BreadCrumbs from "../components/BreadCrumbs"

import breakpoints from "../styles/breakpoints"
import localeData from "../locale"
import PrimaryBtn from "../components/Buttons/PrimaryBtn"
// import vSpacing from "../styles/vSpacing"
import { currentLocale } from "../locale/languages"

import slugify from "../utils/slugify"

// const Title4 = styled.h2`
//   color: ${props => props.theme.primaryColors.primary};
//   padding-bottom: ${vSpacing.baseX3};
//   text-align: center;
//   ${breakpoints.md`
//     text-align: left;
//   `};
// `

const Title3 = styled.h3`
  text-align: center;
  width: 100%;
  margin-bottom: 32px;
  color: ${props => props.theme.primaryColors.primary};
  ${breakpoints.md`
    margin-bottom: 56px;
  `};
`

const FaqBlock = styled(Grid)`
  margin-bottom: 40px;
  ${breakpoints.md`
    margin-bottom: 88px;
  `};
`

const FaqTitle = styled.h5`
  font-weight: 600;
  margin-bottom: 40px;
  color: ${props => props.theme.colors.black900};
`
const HelpTitle = styled.h2`
  color: ${props => props.theme.primaryColors.primary};
  text-align: center;
  margin-top: 40px;
`
const HelpText = styled.p`
  text-align: center;
  margin-bottom: 56px;
  font-size: 20px;
`

export const query = graphql`
  {
    sanityFaqPage {
      _rawLocaleTitle
      _rawHeroTitle
      image {
        asset {
          gatsbyImageData(width: 1600, fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
    allSanityFaqBlock {
      edges {
        node {
          id
          _rawFaqBlockTitle
          _rawFaqs
          faqIcon {
            asset {
              url
            }
          }
        }
      }
    }
    sanityContactPage {
      id
      _rawLocaleTitle
    }
    sanityFaqPage {
      id
      _rawLocaleTitle
    }
  }
`
class Faq extends React.PureComponent {
  render() {
    const { data, pageContext } = this.props
    const { locale } = pageContext
    const { _rawLocaleTitle, _rawHeroTitle, image } = data.sanityFaqPage

    const faqs = data.allSanityFaqBlock.edges

    const contactPageLink = data.sanityContactPage._rawLocaleTitle
    const FaqPageLink = data.sanityFaqPage._rawLocaleTitle
    const setLocaleInUrl = currentLocale !== locale ? `${locale}/` : ""

    return (
      <>
        <SEO title={_rawLocaleTitle[locale]} />
        {_rawLocaleTitle && (
          <BreadCrumbs
            color="white"
            pageTitle={_rawLocaleTitle[locale]}
            locale={locale}
          />
        )}
        {image && (
          <HeroFullWidth
            img={image}
            title={_rawHeroTitle[locale]}
          />
        )}
        {/* {faqCategory && faqCategory._rawFaqs && (
          <Container paddingTop paddingBottom>
            <Section>
              <Grid container spacing={0} justify="center">
                <Grid item xs={12} md={8}>
                  <Title4>{localeData._rawFaqTitle[locale]}</Title4>
                  {faqCategory._rawFaqs &&
                    faqCategory._rawFaqs.map(faqItem => (
                      <AccordionItem
                        key={faqItem._key}
                        header={faqItem.question[locale]}
                        body={faqItem.answer[locale]}
                      />
                    ))}
                </Grid>
              </Grid>
            </Section>
          </Container>
        )} */}
        {faqs && (
          <SvgBorderTop grey>
            <Container grey paddingBottom paddingTop>
              <Section>
                <Title3>{localeData._rawFaqFilterTitle[locale]}</Title3>
                <Grid container spacing={16} justify="center">
                  {faqs.map(item => (
                    <Grid item xs={12} md={3} key={item.node.id}>
                      <AnchorLink
                        to={`/${setLocaleInUrl}${slugify(
                          FaqPageLink[locale]
                        )}#${slugify(item.node._rawFaqBlockTitle[locale])}`}
                      >
                        <CardBlockWithIcon
                          icon={item.node.faqIcon}
                          content={item.node._rawFaqBlockTitle[locale]}
                        />
                      </AnchorLink>
                    </Grid>
                  ))}
                </Grid>
              </Section>
            </Container>
          </SvgBorderTop>
        )}
        {faqs && (
          <SvgBorderTop paddingTop>
            <Container paddingBottom>
              <Section>
                <Grid container spacing={0} justify="center">
                  {faqs.map(faq => (
                    <FaqBlock item xs={12} md={8} key={faq.node.id}>
                      {faq.node._rawFaqBlockTitle && (
                        <div
                          id={`${slugify(faq.node._rawFaqBlockTitle[locale])}`}
                        >
                          <FaqTitle>
                            {faq.node._rawFaqBlockTitle[locale]}
                          </FaqTitle>
                        </div>
                      )}
                      {faq.node._rawFaqs &&
                        faq.node._rawFaqs.map(faqItem => (
                          <AccordionItem
                            key={faqItem._key}
                            header={faqItem.question[locale]}
                            body={faqItem.answer}
                            locale={locale}
                          />
                        ))}
                    </FaqBlock>
                  ))}
                </Grid>
              </Section>
            </Container>
          </SvgBorderTop>
        )}
        <SvgBorderTop grey>
          <Container paddingBottom grey>
            <Section>
              <Grid container justify="center">
                <Grid item xs={12} md={6} container justify="center">
                  <HelpTitle>{localeData._rawFaqHelpTitle[locale]}</HelpTitle>
                  <HelpText>{localeData._rawFaqHelpText[locale]}</HelpText>
                  <Grid container justify="center">
                    <Link
                      to={`/${setLocaleInUrl}${slugify(
                        contactPageLink[locale]
                      )}`}
                    >
                      <PrimaryBtn>
                        {localeData._rawContactUs[locale]}
                      </PrimaryBtn>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Section>
          </Container>
        </SvgBorderTop>
      </>
    )
  }
}

export default Faq
