import React from "react"
import styled from "styled-components"
import vSpacing from "../../styles/vSpacing"
import Card from "../Card"
import breakpoints from "../../styles/breakpoints"

const Container = styled(Card)`
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${breakpoints.md`
    text-align: center;
    flex-direction: column;
    padding: 28px;    
  `};
  transition: ${props => props.theme.transitions.hover};
  &:hover {
    cursor: pointer;
    box-shadow: ${props => props.theme.boxShadow.zIndex5};
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${vSpacing.baseX2};
  ${breakpoints.md`
    justify-content: center;
    margin-right: 0;
    margin-bottom: ${vSpacing.baseX3};
    svg {
        height: 64px;
        width: 64px;
    }
  `};
`

const CardBlockWithIcon = ({ icon, content }) => (
  <Container>
    {icon && (
      <Icon>
        <img
          src={icon.asset.url}
          alt={content || "faq"}
        />
      </Icon>
    )}
    <p>{content}</p>
  </Container>
)

export default CardBlockWithIcon
